import axios from "axios";

const axiosinstance = axios.create({
  baseURL: "https://ot07x9lfg1.execute-api.ap-south-1.amazonaws.com",
  headers: {
    "Content-Type": "application/json",
  },
});

const axiosinstance1 = axios.create({
  baseURL: "https://xev1vc6ve6.execute-api.ap-south-1.amazonaws.com",
  headers: {
    "Content-Type": "application/json",
  },
});
const axiosinstance2 = axios.create({
  baseURL: "https://amglnp0y4f.execute-api.ap-south-1.amazonaws.com",
  headers: {
    "Content-Type": "application/json",
  },
});
const axiosinstance3 = axios.create({
  baseURL: "https://vdq4hdwmol.execute-api.ap-south-1.amazonaws.com",
  headers: {
    "Content-Type": "application/json",
  },
});
const axiosinstance4 = axios.create({
  baseURL: "https://ycecualcsd.execute-api.ap-south-1.amazonaws.com",
  headers: {
    "Content-Type": "application/json",
  },
});
const axiosinstance5 = axios.create({
  baseURL: "https://swl8k4kn4e.execute-api.ap-south-1.amazonaws.com/",
  headers: {
    "Content-Type": "application/json",
  },
});
const axiosinstance6 = axios.create({
  baseURL: "https://ny7uvv0z80.execute-api.ap-south-1.amazonaws.com/",
  headers: {
    "Content-Type": "application/json",
  },
});
const axiosinstance7 = axios.create({
  baseURL: "https://40wuz0v632.execute-api.ap-south-1.amazonaws.com/",
  headers: {
    "Content-Type": "application/json",
  },
});

const axiosinstance8 = axios.create({
  baseURL:
    "https://wr5luqxg77.execute-api.ap-south-1.amazonaws.com/CreateThing/",
  headers: {
    "Content-Type": "application/json",
  },
});
const axiosinstance9 = axios.create({
  baseURL:
    "https://5lxmy0lic8.execute-api.ap-south-1.amazonaws.com/Dev_validateuser/",
  headers: {
    "Content-Type": "application/json",
  },
});

export {
  axiosinstance,
  axiosinstance1,
  axiosinstance2,
  axiosinstance3,
  axiosinstance4,
  axiosinstance5,
  axiosinstance6,
  axiosinstance7,
  axiosinstance8,
  axiosinstance9,
};
