import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

const AuthLayout = ({ authentication = true }) => {
  const authstatus = useSelector((state) => state.auth.authstatus);
  const navigate = useNavigate();

  useEffect(() => {
    if (authentication && authentication !== authstatus) {
      navigate("/login");
    } else if (!authentication && authentication !== authstatus) {
      navigate("/");
    }
  }, [authentication, authstatus]);

  return <Outlet />;
};

export default AuthLayout;
