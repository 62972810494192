import React from "react";
import { List, ListItem, ListItemText } from "@mui/material";

const ListComponent = ({ data, dataKey }) => {
  return (
    <List>
      {data.map((item, index) => (
        <ListItem key={`${item[dataKey]}-${index}`}>
          <ListItemText primary={item[dataKey]} />
        </ListItem>
      ))}
    </List>
  );
};

export default ListComponent;
