import React from "react";
import { Container, Typography, Box } from "@mui/material";
import ComingSoon from "../../component/ComingSoon";
import DatePickerdata from "../../component/DatePicker";


const Reports = () => {
  return (
    <>

      <ComingSoon pageName="Reports" />
    </>
  )

};

export default Reports;
