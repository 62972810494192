import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Layout from "./component/Layout/Layout";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login/Index";
import AuthLayout from "./component/AuthLayout";
import Profile from "./pages/Profile.jsx";
import ViewThings from "./pages/Things/index.jsx";
import ThingsDashboard from "./pages/ThingsDashboard/index.jsx";
import CreateThings from "./pages/CreateThings/index.jsx";
import RuleEngine from "./pages/RuleEngine/index.js";
import Reports from "./pages/Reports/index.js";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Dashboard />} />
      <Route element={<ViewThings />} path="view-things" />
      <Route element={<ThingsDashboard />} path="thingsdashboard/:slug" />
      <Route element={<CreateThings />} path="create-things" />
      <Route element={<RuleEngine />} path="ruleengine" />
      <Route element={<Reports />} path="reports" />

      <Route element={<AuthLayout authentication={false} />}>
        <Route element={<Login />} path="login" />
      </Route>
      <Route element={<AuthLayout authentication />}>
        <Route element={<Profile />} path="profile" />
      </Route>
    </Route>
  )
);

export default router;
