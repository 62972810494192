import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { axiosinstance5 } from "../../services/baseUrl";

// Fix for default icon issue with Leaflet
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const MapComponent = () => {
  const [data, setData] = useState([]);

  const currentAndPreviousLocationf = async () => {
    const currentDate = new Date();
    const oneWeekAgoDate = new Date(currentDate);
    oneWeekAgoDate.setDate(currentDate.getDate() - 7);

    const formatDate = (date) =>
      date.toISOString().slice(0, 19).replace("T", " ");
    const start_date = formatDate(oneWeekAgoDate);
    const end_date = formatDate(currentDate);

    try {
      const response = await axiosinstance5.post(
        "currentAndPreviousLocation/currentAndPreviousLocation",
        { start_date, end_date }
      );

      if (response.data.statusCode === 200) {
        const enrichedData = await Promise.all(
          response.data.body.map(async (item) => {
            const latitude = parseFloat(item.latitude);
            const longitude = parseFloat(item.longitude);
            const location = await fetchLocationName(latitude, longitude);
            return {
              latitude,
              longitude,
              thingid: item.thingid,
              timestamp: item.timestamp,
              location: location || "Unknown Location",
            };
          })
        );
        setData(enrichedData);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchLocationName = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&accept-language=en`
      );
      const data = await response.json();
      return data.display_name;
    } catch (error) {
      console.error("Error fetching location name", error);
      return null;
    }
  };

  useEffect(() => {
    currentAndPreviousLocationf();
  }, []);

  return (
    <MapContainer
      center={[28.0367311, 76.0912824]}
      zoom={5}
      style={{ height: "500px", width: "100%" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; "
      />
      {data.map((location, index) => (
        <Marker key={index} position={[location.latitude, location.longitude]}>
          <Popup>
            Location: {location.location}
            <br />
            ThingID: {location.thingid}
            <br />
            Timestamp: {location.timestamp}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapComponent;
