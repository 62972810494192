import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Container,
  TextField,
  IconButton,
  Toolbar,
  Typography,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import { DashboardWrapper } from "./ThingsStyle";
import { axiosinstance7 } from "../../services/baseUrl";
import { useNavigate } from "react-router-dom";

const DataTable = () => {
  const [rows, setRows] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(5);
  const [searchText, setSearchText] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    getAllThings();
  }, []);

  const getAllThings = async () => {
    try {
      const response = await axiosinstance7.get("GetAllThings/getAllThings");
      const data = response.data.body.map((item, index) => ({
        id: index + 1,
        thingName: item.thingName,
        mobileImei: item.attributes.mobileImei,
        mobileName: item.attributes.mobileName,
        mobileNo: item.attributes.mobileNo,
        version: item.version,
      }));
      setRows(data);
      setFilteredRows(data);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleView = (thingName) => {
    navigate(`/thingsdashboard/${thingName}`);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);
    const filtered = rows.filter(
      (row) =>
        (row.thingName &&
          row.thingName.toLowerCase().includes(value.toLowerCase())) ||
        (row.mobileName &&
          row.mobileName.toLowerCase().includes(value.toLowerCase())) ||
        (row.mobileNo &&
          row.mobileNo.toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredRows(filtered);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "thingName",
      headerName: "Thing Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "mobileImei",
      headerName: "Mobile IMEI",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "mobileName",
      headerName: "Mobile Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "mobileNo",
      headerName: "Mobile Number",
      flex: 1,
      minWidth: 150,
    },
    { field: "version", headerName: "Version", width: 100 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleView(params.row.thingName)}
            aria-label="view">
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <DashboardWrapper className="custom_box">
      <Container maxWidth="xl">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            All Things
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchText}
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </Toolbar>
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15]}
            pagination
          />
        </Box>
      </Container>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardWrapper>
  );
};

export default DataTable;
