import React, { forwardRef, useId } from "react";
import { Form } from "react-bootstrap";
import TextField from "@mui/material/TextField";

const Input = forwardRef(
  ({ label, type = "text", className, ...props }, ref) => {
    const inputid = useId();
    return (
      <>
        {/* <Form.Group className="mb-3" controlId={inputid}>
          {label && <Form.Label>{label}</Form.Label>}
          <Form.Control type={type} {...props} ref={ref} />
        </Form.Group> */}
        {label && (
          <TextField
            fullWidth
            label={label}
            id="argin-none"
            {...props}
            ref={ref}
            margin="dense"
          />
        )}
      </>
    );
  }
);

export default Input;
