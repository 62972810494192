import React, { useEffect, useState } from "react";
import { Container, Grid, Card, CardContent, Typography } from "@mui/material";
import LineChartComponent from "../../component/LineChartComponent";
import ListComponent from "../../component/ListComponent";
import BarChartComponent from "../../component/BarChartComponent";
import PieChartComponent from "../../component/PieChartComponent"; // Import the new PieChartComponent
import {
  axiosinstance,
  axiosinstance1,
  axiosinstance2,
  axiosinstance3,
  axiosinstance4,
  axiosinstance5,
  axiosinstance6,
} from "../../services/baseUrl";
import DataUsage from "../../component/DataUsage";
import MapComponent from "../../component/ScatterChart";
import { DashboardWrapper } from "../Things/ThingsStyle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Dashboard = () => {
  const [graphData, setGraphData] = useState([]);
  const [graphDataBattery, setGraphDataBattery] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [sortedDataBattery, setSortedDataBattery] = useState([]);
  const [totalAppNumberData, setTotalAppNumberData] = useState([]);
  const [appUsageTimeData, setAppUsageTimeData] = useState([]);
  const [RemaningBattery, setRemaningBattery] = useState([]);
  const [ChargingCount, setChargingCount] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentAndPreviousLocation, setcurrentAndPreviousLocation] = useState(
    []
  );

  const formatDate = (date) => {
    const tzOffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOTime = new Date(date - tzOffset)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    return localISOTime;
  };

  const fetchData = async () => {
    const currentDate = new Date();
    const oneWeekAgoDate = new Date(currentDate);
    oneWeekAgoDate.setDate(currentDate.getDate() - 7);

    const start_date = startDate
      ? formatDate(startDate)
      : formatDate(oneWeekAgoDate);
    const end_date = endDate ? formatDate(endDate) : formatDate(currentDate);

    try {
      const response = await axiosinstance.post("/Dev/totalAppDataUsage", {
        start_date,
        end_date,
      });

      const formattedData = response?.data?.data?.map((item) => ({
        timestamp: item.timestamp,
        data_usage_foreground: Number(item.data_usage_foreground),
        data_usage_background: Number(item.data_usage_background),
      }));

      setGraphData(formattedData);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchDataBatteryData = async () => {
    const currentDate = new Date();
    const oneWeekAgoDate = new Date(currentDate);
    oneWeekAgoDate.setDate(currentDate.getDate() - 7);

    const start_date = startDate
      ? formatDate(startDate)
      : formatDate(oneWeekAgoDate);
    const end_date = endDate ? formatDate(endDate) : formatDate(currentDate);

    try {
      const response = await axiosinstance1.post(
        "dev_batter/totalAppBatteryConsumption",
        {
          start_date,
          end_date,
        }
      );

      const formattedData = response?.data?.data.map((item) => ({
        timestamp: item.timestamp,
        battery_consumption_foreground: Number(
          item.battery_consumption_foreground
        ),
        battery_consumption_background: Number(
          item.battery_consumption_background
        ),
      }));

      setGraphDataBattery(formattedData);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchListData = async () => {
    const currentDate = new Date();
    const oneWeekAgoDate = new Date(currentDate);
    oneWeekAgoDate.setDate(currentDate.getDate() - 7);

    const start_date = startDate
      ? formatDate(startDate)
      : formatDate(oneWeekAgoDate);
    const end_date = endDate ? formatDate(endDate) : formatDate(currentDate);

    try {
      const response = await axiosinstance.post("/Dev/totalAppDataUsage", {
        start_date,
        end_date,
        isSelectApp: "true",
      });

      const alldata = response?.data?.data;

      const processData = (alldata) => {
        const filteredData = alldata.filter(
          (item) =>
            parseInt(item.data_usage_foreground) !== 0 ||
            parseInt(item.data_usage_background) !== 0
        );

        const sortedData = filteredData.sort((a, b) => {
          const foregroundA = parseInt(a.data_usage_foreground);
          const foregroundB = parseInt(b.data_usage_foreground);
          const backgroundA = parseInt(a.data_usage_background);
          const backgroundB = parseInt(b.data_usage_background);

          if (foregroundA !== foregroundB) {
            return foregroundB - foregroundA;
          }
          return backgroundB - backgroundA;
        });

        return sortedData;
      };
      const sortedData = processData(alldata);
      setSortedData(sortedData);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchListDataBattery = async () => {
    const currentDate = new Date();
    const oneWeekAgoDate = new Date(currentDate);
    oneWeekAgoDate.setDate(currentDate.getDate() - 7);

    const start_date = startDate
      ? formatDate(startDate)
      : formatDate(oneWeekAgoDate);
    const end_date = endDate ? formatDate(endDate) : formatDate(currentDate);

    try {
      const response = await axiosinstance1.post(
        "dev_batter/totalAppBatteryConsumption",
        {
          start_date,
          end_date,
          isSelectApp: "true",
        }
      );

      const alldata = response?.data?.data;

      const processData = (alldata) => {
        const filteredData = alldata.filter(
          (item) =>
            parseInt(item.battery_consumption_foreground) !== 0 ||
            parseInt(item.battery_consumption_background) !== 0
        );

        const sortedData = filteredData.sort((a, b) => {
          const foregroundA = parseInt(a.battery_consumption_foreground);
          const foregroundB = parseInt(b.battery_consumption_foreground);
          const backgroundA = parseInt(a.battery_consumption_background);
          const backgroundB = parseInt(b.battery_consumption_background);

          if (foregroundA !== foregroundB) {
            return foregroundB - foregroundA;
          }
          return backgroundB - backgroundA;
        });

        return sortedData;
      };
      const sortedData = processData(alldata);
      setSortedDataBattery(sortedData);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchTotalAppNumber = async () => {
    const currentDate = new Date();
    const oneWeekAgoDate = new Date(currentDate);
    oneWeekAgoDate.setDate(currentDate.getDate() - 7);

    const start_date = startDate
      ? formatDate(startDate)
      : formatDate(oneWeekAgoDate);
    const end_date = endDate ? formatDate(endDate) : formatDate(currentDate);

    try {
      const response = await axiosinstance2.post(
        "/TotalNumber/totalAppNumberOfTime",
        {
          start_date,
          end_date,
        }
      );

      const formattedData = response.data.data.map((item, index) => ({
        timestamp: item.timestamp,
        launch_count: Number(item.launch_count),
        key: `${item.timestamp}-${index}`,
      }));

      setTotalAppNumberData(formattedData);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchTotalAppUsageTime = async () => {
    const currentDate = new Date();
    const oneWeekAgoDate = new Date(currentDate);
    oneWeekAgoDate.setDate(currentDate.getDate() - 7);

    const start_date = startDate
      ? formatDate(startDate)
      : formatDate(oneWeekAgoDate);
    const end_date = endDate ? formatDate(endDate) : formatDate(currentDate);

    try {
      const response = await axiosinstance3.post(
        "/Dev-Time-Stamp/totalAppUsageTime",
        {
          start_date,
          end_date,
        }
      );

      const formattedData = response?.data?.data?.map((item) => ({
        name: `${item.timestamp} (${item.average_usage_time_attribute})`,
        value: Number(item.average_usage_time),
      }));

      setAppUsageTimeData(formattedData);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const RemaningBatteryLife = async () => {
    const currentDate = new Date();
    const oneWeekAgoDate = new Date(currentDate);
    oneWeekAgoDate.setDate(currentDate.getDate() - 7);

    const start_date = startDate
      ? formatDate(startDate)
      : formatDate(oneWeekAgoDate);
    const end_date = endDate ? formatDate(endDate) : formatDate(currentDate);

    try {
      const response = await axiosinstance4.post("/Dev/RemaningBatteryLife", {
        start_date,
        end_date,
      });

      setRemaningBattery(response?.data?.body);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const currentAndPreviousLocationf = async () => {
    const currentDate = new Date();
    const oneWeekAgoDate = new Date(currentDate);
    oneWeekAgoDate.setDate(currentDate.getDate() - 7);

    const start_date = startDate
      ? formatDate(startDate)
      : formatDate(oneWeekAgoDate);
    const end_date = endDate ? formatDate(endDate) : formatDate(currentDate);

    try {
      const response = await axiosinstance5.post(
        "currentAndPreviousLocation/currentAndPreviousLocation",
        {
          start_date,
          end_date,
        }
      );
      if (response.status === 200) {
        const formattedData = response?.data?.body?.map((item) => ({
          x: parseFloat(item.latitude),
          y: parseFloat(item.longitude),
          z: item.timestamp,
        }));
        setcurrentAndPreviousLocation(formattedData);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const charging_count = async () => {
    const currentDate = new Date();
    const oneWeekAgoDate = new Date(currentDate);
    oneWeekAgoDate.setDate(currentDate.getDate() - 7);

    const start_date = startDate
      ? formatDate(startDate)
      : formatDate(oneWeekAgoDate);
    const end_date = endDate ? formatDate(endDate) : formatDate(currentDate);

    try {
      const response = await axiosinstance6.post(
        "HowManyTimeDeviceWasCharged/HowManyTimeDeviceWasCharged",
        {
          start_date,
          end_date,
        }
      );
      setChargingCount(response.data.body);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchListData();
    fetchDataBatteryData();
    fetchListDataBattery();
    fetchTotalAppNumber();
    fetchTotalAppUsageTime();
    RemaningBatteryLife();
    currentAndPreviousLocationf();
    charging_count();
  }, [startDate, endDate]);

  const getUniqueList = (data, key, limit) => {
    const uniqueItems = [];
    const seenItems = new Set();

    for (const item of data) {
      if (!seenItems.has(item[key])) {
        uniqueItems.push(item);
        seenItems.add(item[key]);
      }
      if (uniqueItems.length === limit) break;
    }

    return uniqueItems;
  };

  const batterydata = () => {
    const formattedBatteryData = RemaningBattery.map((data) => {
      return {
        ...data,
        battery_level: parseInt(data.battery_level, 10),
      };
    });

    const mergedData = graphDataBattery
      .map((consumption) => {
        const correspondingBatteryLife = formattedBatteryData.find(
          (life) => life.timestamp === consumption.timestamp
        );
        return {
          ...consumption,
          battery_level: correspondingBatteryLife
            ? correspondingBatteryLife.battery_level
            : null,
        };
      })
      .filter((data) => data.battery_level !== null);

    const chartData = mergedData.map((data) => ({
      timestamp: data.timestamp,
      battery_consumption_foreground: data.battery_consumption_foreground,
      battery_consumption_background: data.battery_consumption_background,
      battery_level: data.battery_level,
    }));
    return chartData;
  };

  return (
    <>
      <DashboardWrapper className="dashbord_main custom_box">
        <Container maxWidth="xl">
          <div className="customdate text-end">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
            />
          </div>

          <section className="py-5">
            <Grid container spacing={3}>
              {graphData?.length > 0 && (
                <Grid item xs={12} lg={6}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">Data Usage</Typography>
                      <DataUsage
                        data={graphData}
                        dataKeyForeground="data_usage_foreground"
                        dataKeyBackground="data_usage_background"
                        title="Data Usage"
                      />
                    </CardContent>
                  </Card>
                </Grid>
              )}
              <Grid item xs={12} lg={6}>
                <Card>
                  <CardContent>
                    {sortedData?.length > 0 && (
                      <ListComponent
                        data={getUniqueList(sortedData, "app_name", 5)}
                        dataKey="app_name"
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>

              {graphDataBattery?.length > 0 && (
                <Grid item xs={12} lg={6}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">Battery Consumption</Typography>
                      <LineChartComponent
                        data={batterydata()}
                        dataKeyForeground="battery_consumption_foreground"
                        dataKeyBackground="battery_consumption_background"
                        batter_life="batter_life"
                        title="Battery Consumption"
                      />
                    </CardContent>
                  </Card>
                </Grid>
              )}
              <Grid item xs={12} lg={6}>
                <Card>
                  <CardContent>
                    {sortedDataBattery?.length > 0 && (
                      <ListComponent
                        data={getUniqueList(sortedDataBattery, "app_name", 5)}
                        dataKey="app_name"
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Card>
                  <CardContent>
                    {totalAppNumberData?.length > 0 && (
                      <BarChartComponent
                        data={totalAppNumberData}
                        dataKey="launch_count"
                        xAxisKey="timestamp"
                        title="App Launch Count Over Time"
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Card>
                  <CardContent>
                    {appUsageTimeData?.length > 0 && (
                      <PieChartComponent
                        data={appUsageTimeData}
                        title="App Usage Time Distribution"
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Card>
                  <CardContent>
                    {ChargingCount?.length > 0 && (
                      <BarChartComponent
                        data={ChargingCount}
                        dataKey="charging_count"
                        xAxisKey="date"
                        title="Charging Counts Over Time"
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Card>
                  <CardContent>
                    {currentAndPreviousLocation?.length > 0 && (
                      <MapComponent title="Location Scatter Chart" />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </section>
        </Container>
      </DashboardWrapper>
    </>
  );
};

export default Dashboard;
