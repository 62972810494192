import React from "react";
import { Container, Typography, Box } from "@mui/material";

const ComingSoon = ({ pageName }) => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "500px",
        textAlign: "center",
      }}>
      <Box>
        <Typography variant="h3" component="h1" gutterBottom>
          Coming Soon
        </Typography>
        <Typography variant="h6" component="p">
          Our {pageName} page is under construction and will be available soon.
        </Typography>
      </Box>
    </Container>
  );
};

export default ComingSoon;
