import React from "react";
import { Form } from "react-bootstrap";
import Input from "../../component/formcomponent/Input";
import { useForm } from "react-hook-form";
import { axiosinstance8 } from "../../services/baseUrl";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Container from "@mui/material/Container";
import { DashboardWrapper } from "../Things/ThingsStyle";

const CreateThings = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const newthings = async (data) => {
    try {
      const response = await axiosinstance8.post("createThing", {
        thingName: data.thingName,
        attributes: {
          mobileName: data.mobileName,
          mobileImei: data.mobileImei,
          mobileNo: data.mobileNo,
        },
      });
      if (response?.data?.statusCode === 200) {
        navigate("/view-things");
      }
      console.log(response?.data?.statusCode);
    } catch (error) {
      console.error("Error creating thing:", error);
    }
  };

  return (
    <>
      <Form className="form_box" onSubmit={handleSubmit(newthings)}>
        <DashboardWrapper className="custom_box">
          <Container maxWidth="xl">
            <Input
              label="Thing Name"
              type="text"
              placeholder="Enter your Thing Name"
              {...register("thingName", { required: true })}
            />
            {errors?.thingName && (
              <p style={{ color: "red", textAlign: "left" }}>
                Thing Name is required
              </p>
            )}

            <Input
              label="Mobile Name"
              type="text"
              placeholder="Enter your Mobile Name"
              {...register("mobileName", { required: true })}
            />
            {errors?.mobileName && (
              <p style={{ color: "red", textAlign: "left" }}>
                Mobile Name is required
              </p>
            )}

            <Input
              label="Mobile IMEI"
              type="text"
              placeholder="Enter your Mobile IMEI"
              {...register("mobileImei", { required: true })}
            />
            {errors?.mobileImei && (
              <p style={{ color: "red", textAlign: "left" }}>
                Mobile IMEI is required
              </p>
            )}

            <Input
              label="Mobile No"
              type="text"
              placeholder="Enter your Mobile No"
              {...register("mobileNo", { required: true })}
            />
            {errors?.mobileNo && (
              <p style={{ color: "red", textAlign: "left" }}>
                Mobile No is required
              </p>
            )}
            <p></p>
            <Button
              className="more_btn"
              type="submit"
              variant="contained"
              endIcon={<SendIcon />}>
              Create Things
            </Button>
          </Container>
        </DashboardWrapper>
      </Form>
    </>
  );
};

export default CreateThings;
