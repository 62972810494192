import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const LineChartComponent = ({
  data = [],
  dataKeyForeground = "battery_consumption_foreground",
  dataKeyBackground = "battery_consumption_background",
  batter_life = "batter_life",
  title = "",
}) => {
  return (
    <div className="dashboard-chart">
      <h3 className="dashboard-title">{title}</h3>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="timestamp" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="battery_level"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
            name="Battery Level (%)"
          />
          <Line
            type="monotone"
            dataKey="battery_consumption_foreground"
            stroke="#82ca9d"
            name="Battery Consumption Foreground"
          />
          <Line
            type="monotone"
            dataKey="battery_consumption_background"
            stroke="#ff7300"
            name="Battery Consumption Background"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartComponent;
