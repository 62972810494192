import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import {
  Dashboard as DashboardIcon,
  Visibility as VisibilityIcon,
  Create as CreateIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import RuleIcon from "@mui/icons-material/Gavel"; // Icon for RuleEngine
import ReportIcon from "@mui/icons-material/Assessment"; // Icon for Reports

const menuItems = [
  { text: "Dashboard", icon: <DashboardIcon />, path: "/" },
  { text: "View Things", icon: <VisibilityIcon />, path: "/view-things" },
  { text: "Create Things", icon: <CreateIcon />, path: "/create-things" },
  { text: "Login", icon: <LockIcon />, path: "/login" },
  { text: "RuleEngine", icon: <RuleIcon />, path: "/ruleengine" },
  { text: "Reports", icon: <ReportIcon />, path: "/reports" },
];

const Sidenav = ({ mobileOpen, handleDrawerToggle }) => {
  const handleNavigation = (event, path) => {
    if (window.innerWidth <= 991 && typeof handleDrawerToggle === "function") {
      handleDrawerToggle(); // Close the drawer
    }
    window.location.href = path; // Navigate to the path
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" className="side_menu">
      <List>
        {menuItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              component="a"
              onClick={(event) => handleNavigation(event, item.path)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {/* Temporary drawer for screens up to 990px */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "block", md: "block", lg: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 250 },
        }}>
        {DrawerList}
      </Drawer>

      <Drawer
        variant="persistent"
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 250,
            "@media (max-width: 991px)": {
              display: "none",
            },
            "@media (min-width: 991px)": {
              display: "block",
            },
          },
        }}
        open>
        {DrawerList}
      </Drawer>
    </div>
  );
};

export default Sidenav;
