import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidenav from "../Sidebar/Index";
import { LayoutWrapper } from "./LayoutStyle";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <Header />

      <LayoutWrapper>
        <Sidenav />
        <Outlet />
      </LayoutWrapper>
      <Footer />
    </>
  );
};

export default Layout;
