import React from "react";
import { Form } from "react-bootstrap";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Container from "@mui/material/Container";
import { DashboardWrapper } from "../Things/ThingsStyle";
import Input from "../../component/formcomponent/Input";
import { useForm } from "react-hook-form";
import { axiosinstance9 } from "../../services/baseUrl";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const login = async (data) => {
    // console.log("data check >>>", data);
    try {
      const response = await axiosinstance9.post("validateUser", data);
      if (response?.data?.statusCode === 200) {
        navigate("/");
      }
      console.log("response >>>", response.data.statusCode);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <DashboardWrapper className="ms-0 custom_box">
        <Form onSubmit={handleSubmit(login)} className="form_box">
          <Container maxWidth="xl">
            <Input
              label="Username"
              type="text"
              placeholder="Enter your Username"
              {...register("username", {
                required: {
                  value: true,
                  message: "This field is Required",
                },
                minLength: {
                  value: 3,
                  message: "Full Name must be at least 3 characters",
                },
                maxLength: {
                  value: 30,
                  message: "Full Name cannot exceed 30 characters",
                },
              })}
            />
            {errors?.username && (
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.username.message}
              </p>
            )}

            <Input
              label="Password"
              type="password"
              placeholder="Enter your Password"
              {...register("password", {
                required: {
                  value: true,
                  message: "This Field is required",
                },
                minLength: {
                  value: 4,
                  message: "Password must be at least 4 characters",
                },
                maxLength: {
                  value: 30,
                  message: "Password cannot exceed 30 characters",
                },
              })}
            />
            {errors?.password && (
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.password.message}
              </p>
            )}
            <p></p>
            <Button
              className="more_btn"
              margin="dense"
              type="submit"
              variant="contained"
              endIcon={<SendIcon />}>
              Login
            </Button>
          </Container>
        </Form>
      </DashboardWrapper>
    </>
  );
};

export default Login;
